// Define a type for category objects
interface Category {
  id: string;
  originalId: string;
  name?: string;
  title?: string;
  parentId?: string | null;
  value?: string;
  position?: number;
  children?: Category[];
  [key: string]: any; // For other properties that might exist
}

export const buildTree: any = (categories: Category[] = []) => {
  let end = true;
  const newArr: any = [];

  // Sort the input categories by position
  const sortedCategories = [...categories].sort((a: Category, b: Category) => {
    // If both have position, sort by position
    if (a.position !== undefined && b.position !== undefined) {
      return a.position - b.position;
    }
    // If only one has position, prioritize the one with position
    if (a.position !== undefined) return -1;
    if (b.position !== undefined) return 1;
    // If neither has position, maintain original order
    return 0;
  });

  sortedCategories.forEach((category: Category) => {
    const _category: any = {
      id: category.id,
      originalId: category.originalId,
      key: category.originalId,
      name: category.name,
      title: category.name,
      parentId: category.parentId,
      value: category.originalId,
      position: category.position,
      checkable: true,
      selectable: true,
      className: 'parent-not-clickable',
    };

    const ch = sortedCategories
      .filter((_ch: Category) => _ch.parentId == category.id)
      .sort((a: Category, b: Category) => {
        if (a.position !== undefined && b.position !== undefined) {
          return a.position - b.position;
        }
        if (a.position !== undefined) return -1;
        if (b.position !== undefined) return 1;
        return 0;
      })
      .map((c: Category) => {
        // c.disable = false;
        c.title = c.name;
        c.key = c.originalId;
        c.selectable = true;
        c.value = c.originalId;
        return c;
      });

    const mountedChildren = category.children
      ? category.children
          .filter((mCh: Category) => {
            const mounted = ch.find((_nCh: Category) => _nCh.id == mCh.id);
            return !mounted;
          })
          .sort((a: Category, b: Category) => {
            if (a.position !== undefined && b.position !== undefined) {
              return a.position - b.position;
            }
            if (a.position !== undefined) return -1;
            if (b.position !== undefined) return 1;
            return 0;
          })
      : [];

    if (ch.length > 0) {
      _category.children = [...mountedChildren, ...ch];
      end = false;
      if (_category?.children?.length == 0) {
        _category.checkable = true;
        _category.selectable = false;
        _category.className = 'parent-not-clickable';
      }
      newArr.push(_category);
    } else {
      if (category.children) {
        _category.children = category.children.sort((a: Category, b: Category) => {
          if (a.position !== undefined && b.position !== undefined) {
            return a.position - b.position;
          }
          if (a.position !== undefined) return -1;
          if (b.position !== undefined) return 1;
          return 0;
        });
      }
    }

    if (ch.length == 0 && category.parentId == null) {
      if (_category?.children?.length == 0 || !_category?.children) {
        _category.checkable = true;
        _category.selectable = true;
        _category.className = '';
      }

      newArr.push(_category);
    }
  });

  return end ? newArr : buildTree(newArr);
};

export const buildSelectTree: any = (categories: Category[] = []) => {
  let end = true;
  const newArr: any = [];

  // Sort the input categories by position
  const sortedCategories = [...categories].sort((a: Category, b: Category) => {
    // If both have position, sort by position
    if (a.position !== undefined && b.position !== undefined) {
      return a.position - b.position;
    }
    // If only one has position, prioritize the one with position
    if (a.position !== undefined) return -1;
    if (b.position !== undefined) return 1;
    // If neither has position, maintain original order
    return 0;
  });

  sortedCategories.forEach((category: Category) => {
    const _category: any = {
      id: category.id,
      originalId: category.originalId,
      title: category?.name || category?.title,
      name: category?.name || category?.title,
      parentId: category?.parentId ?? null,
      value: category.originalId,
      position: category.position,
      className: '',
    };

    // Get children for this category and sort them by position
    const ch = sortedCategories
      .filter((_ch: Category) => _ch.parentId == category.id)
      .sort((a: Category, b: Category) => {
        if (a.position !== undefined && b.position !== undefined) {
          return a.position - b.position;
        }
        if (a.position !== undefined) return -1;
        if (b.position !== undefined) return 1;
        return 0;
      })
      .map((c: Category) => {
        // c.disable = false;
        c.title = c.name;
        c.value = c.originalId;
        return c;
      });

    const mountedChildren = category.children
      ? category.children
          .filter((mCh: Category) => {
            const mounted = ch.find((_nCh: Category) => _nCh.id == mCh.id);
            return !mounted;
          })
          // Sort mounted children by position as well
          .sort((a: Category, b: Category) => {
            if (a.position !== undefined && b.position !== undefined) {
              return a.position - b.position;
            }
            if (a.position !== undefined) return -1;
            if (b.position !== undefined) return 1;
            return 0;
          })
      : [];

    if (ch.length > 0) {
      _category.children = [...mountedChildren, ...ch];
      end = false;

      newArr.push(_category);
    } else {
      if (category.children) {
        // Sort category.children by position if it exists
        _category.children = category.children.sort((a: Category, b: Category) => {
          if (a.position !== undefined && b.position !== undefined) {
            return a.position - b.position;
          }
          if (a.position !== undefined) return -1;
          if (b.position !== undefined) return 1;
          return 0;
        });
      }
    }

    if (ch.length == 0 && category.parentId == null) {
      newArr.push(_category);
    }
  });

  return end ? newArr : buildSelectTree(newArr);
};
