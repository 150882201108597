import { Form, Input, Tooltip } from 'antd';
import api from '../../../api';
import { toastNotification } from '../../../utils/toastNotification';
import { useEffect, useState } from 'react';
import { ExchangeRatesModel } from '../../../interfaces/models/ExchangeRates.model';

const PriceField = ({ index, form, restField, id, shopId }: any) => {
  const name = ['variants', index];
  const priceExtended = form.getFieldValue([...name, 'price_extended']);
  const [prices, setPrices] = useState<ExchangeRatesModel[]>([]);

  // Extract prices from price_extended based on shopId
  const shopPriceData = priceExtended ? priceExtended[Number(shopId)] : null;
  const price = shopPriceData
    ? Number((shopPriceData.price * (1 + shopPriceData.tvsh / 100)).toFixed(2))
    : undefined;
  const discountPrice = shopPriceData
    ? Number((shopPriceData.discount_price * (1 + shopPriceData.tvsh / 100)).toFixed(2))
    : undefined;
  let purchasePrice = form.getFieldValue([...name, 'purchase_price']);
  let purchasePriceLEK;
  if (shopId == 2) {
    const conversionRateObj = prices.find(price => price.currency_pair === 'EURALL');
    const conversionRate = Number(conversionRateObj?.rate);
    purchasePriceLEK = purchasePrice * conversionRate;
    purchasePrice = purchasePriceLEK;
  }
  const effectivePrice =
    discountPrice !== undefined && discountPrice != 0 ? Number(discountPrice) : Number(price);
  const percentage = ((effectivePrice / purchasePrice - 1) * 100).toFixed(2);

  const handleSetError = (msg: string) => {
    toastNotification('error', msg);
  };

  const getCurrencyExchangeRates = () => {
    api(`exchange/currency/rates`, 'GET', null, { shopId })
      .then(({ result, statusIsOk, statusMessage }) => {
        if (!statusIsOk) handleSetError(statusMessage);
        if (statusIsOk) {
          setPrices(result);
        }
      })
      .catch(({ statusMessage }) => handleSetError(statusMessage));
  };

  useEffect(() => {
    getCurrencyExchangeRates();
  }, []);

  return (
    <>
      <Tooltip
        title={
          <div>
            <p>
              Purchase Price:{' '}
              {shopId == 2 ? purchasePriceLEK?.toFixed(2) : purchasePrice.toFixed(2)}
            </p>
            <p>Sales Price: {price}</p>
            <p>Discount S. Price: {discountPrice}</p>
            <p>Percentage: {percentage}%</p>
          </div>
        }
      >
        {discountPrice == undefined || discountPrice == 0 ? (
          <Form.Item
            {...restField}
            {...(id == 0 && {
              label: 'S.Price',
            })}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="read__variant-last"
          >
            <Input
              autoComplete="off"
              autoCorrect="off"
              placeholder="Price"
              value={price}
              readOnly
            />
          </Form.Item>
        ) : (
          <Form.Item
            {...restField}
            {...(id == 0 && {
              label: 'S.Price',
            })}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="read__variant-last"
          >
            <Input
              autoComplete="off"
              autoCorrect="off"
              placeholder="Price"
              value={discountPrice}
              readOnly
            />
          </Form.Item>
        )}
      </Tooltip>
    </>
  );
};

export default PriceField;
