import { useEffect, useMemo, useRef, useState } from 'react';
import { Pagination, message } from 'antd';
import { LanguageEnum } from '../../../enums/Language.enum';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  AttributeModel,
  BrandModel,
  LanguageModel,
  ProductFilter,
  SellerModel,
  ShopModel,
  UserModel,
} from '../../../interfaces';
import { toastNotification } from '../../../utils/toastNotification';
import api from '../../../api';
import { buildSelectTree } from '../../../utils/buildTree';
import * as XLSX from 'xlsx';
import queryString from 'query-string';
import { CategoriesFilters } from './CategoriesFilters';
import { ViewProductNewDesign } from '../Products/ViewProductNewDesign';
import { ListingProducts } from './ListingProducts';

const CategoriesUpdatePage = () => {
  const { EN } = LanguageEnum;
  const { search } = useLocation();
  const navigate = useNavigate();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [paginationLoading, setPaginationLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [products, setProduct] = useState();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [productCount, setProductCount] = useState(0);
  const [users, setUsers] = useState<UserModel[]>([]);
  const [sellers, setSellers] = useState<SellerModel[]>([]);
  const [languages, setLanguages] = useState<LanguageModel[]>([]);
  const [shop, setShop] = useState<ShopModel>();
  const [brands, setBrands] = useState<BrandModel[]>([]);
  const [shopBrands, setShopBrands] = useState<BrandModel[]>([]);
  const [shopAttributes, setShopAttributes] = useState([]);
  const [shopCategories, setShopCategories] = useState([]);
  const [categorySubmit, setCategorySubmit] = useState(false);
  const [searchParams] = useSearchParams();
  const shopId = searchParams.get('shopId');
  const abortControllerRef = useRef<AbortController | null>(null);

  const [searchValue, setSearchValue] = useState<ProductFilter>({
    language: EN,
  });

  const searchParsed = useMemo(() => queryString.parse(search), [search]);

  const params = useMemo(
    () =>
      queryString.stringify(
        {
          status: searchParsed?.status || searchParsed?.by_status,
          category: searchParsed?.category,
          seller: searchParsed?.seller,
          search_text: searchParsed?.search_text,
          search_type: searchParsed?.search_type,
          user: searchParsed?.user,
          orderKey: searchParsed?.orderKey,
          orderBy: searchParsed?.orderBy,
          rating: searchParsed?.rating,
          language: searchParsed?.language,
          pageSize: searchParsed?.pageSize || 10,
          page: searchParsed?.page || 0,
          show_categories: searchParsed?.show_categories,
          show_translated: searchParsed?.show_translated,
          show_ean: searchParsed?.show_ean,
          show_images: searchParsed?.show_images,
          show_price: searchParsed?.show_price,
          show_stock: searchParsed?.show_stock,
          show_brands: searchParsed?.show_brands,
          show_updated: searchParsed?.show_updated,
          show_is_in_vendor_list: searchParsed?.show_is_in_vendor_list,
        },
        { skipEmptyString: true },
      ),
    [searchParsed],
  );

  const handleSetError = (msg: string, errorType?: 'error' | 'warn' | 'info') => {
    toastNotification(errorType || 'error', msg);
    setLoading(false);
    setLoadingBtn(false);
    setLoadingButton(false);
  };

  const handlePagiantion = (current: number, size: number) => {
    navigate(
      {
        search: queryString.stringify({
          ...searchParsed,
          page: `${current}`,
          pageSize: `${size}`,
        }),
      },
      { replace: true },
    );
  };

  const handleGetAllProducts = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    setLoading(true);
    setPaginationLoading(true);
    api(`category/allProducts?${params}&onlyCount=0`, 'GET', null, { shopId }, signal)
      .then(({ statusIsOk, statusMessage, result }) => {
        if (!statusIsOk) {
          handleSetError(statusMessage);
        }
        if (statusIsOk) {
          setProduct(result);
          setLoading(false);
          setSelectedRows([]);
        }
      })
      .catch((error: any) => {
        if (error.name === 'AbortError') {
          console.log('Request was aborted');
        } else if (error && error.statusMessage) {
          handleSetError(error.statusMessage);
        }
      });

    api(`category/allProducts?${params}&onlyCount=1`, 'GET', null, { shopId }, signal)
      .then(({ statusIsOk, statusMessage, result }) => {
        if (!statusIsOk) {
          handleSetError(statusMessage);
        }
        if (statusIsOk) {
          setProductCount(result);
          setPaginationLoading(false);
        }
      })
      .catch((error: any) => {
        if (error.name === 'AbortError') {
          console.log('Request was aborted');
        } else if (error && error.statusMessage) {
          handleSetError(error.statusMessage);
        }
      });
  };

  const handleSelectAllProducts = () => {
    setLoading(true);
    api(`product/select-all?${params}`, 'GET', null, { shopId })
      .then(({ statusIsOk, statusMessage, result }) => {
        if (!statusIsOk) handleSetError(statusMessage);
        if (statusIsOk) {
          setLoading(false);
          setSelectedRows(result.map((row: { id: string }) => row?.id));
        }
      })
      .catch(({ statusMessage }) => handleSetError(statusMessage));
  };

  const getShopById = () => {
    setLoading(true);
    api(`shops/${shopId}`, 'GET', null, { shopId })
      .then(({ statusIsOk, statusMessage, result }) => {
        if (!statusIsOk) toastNotification('error', statusMessage);
        if (statusIsOk) setShop(result);
      })
      .catch(({ statusMessage }) => toastNotification('error', statusMessage));
  };

  const handleGetAllLanguages = () => {
    setLoading(true);
    api('language', 'GET', null, { shopId })
      .then(({ statusIsOk, statusMessage, result }) => {
        if (!statusIsOk) toastNotification('error', statusMessage);
        if (statusIsOk) setLanguages(result.results);
      })
      .catch(({ statusMessage }) => toastNotification('error', statusMessage));
  };

  const handleGetAllSellers = () => {
    api('seller/getSellers', 'POST', { local_seller: false }, { shopId })
      .then(({ statusIsOk, statusMessage, result }) => {
        if (!statusIsOk) handleSetError(statusMessage);
        if (statusIsOk) setSellers(result);
      })
      .catch(({ statusMessage }) => handleSetError(statusMessage));
  };

  const handleGetAllUsers = () => {
    setLoading(true);
    api('user', 'GET', null, { shopId })
      .then(({ statusIsOk, statusMessage, result }) => {
        if (!statusIsOk) handleSetError(statusMessage);
        if (statusIsOk) {
          setUsers(result);
        }
      })
      .catch(({ statusMessage }) => handleSetError(statusMessage));
  };

  const changeProductsStatus = (status: number) => {
    if (selectedRows.length === 0) return toastNotification('error', 'Please select a product');

    /* eslint-disable camelcase */
    api('product/status', 'POST', { ids: selectedRows, status }, { shopId })
      .then(({ statusIsOk, statusMessage }) => {
        if (!statusIsOk) handleSetError(statusMessage);
        if (statusIsOk) {
          toastNotification('success', statusMessage);
          handleGetAllProducts();
        }
      })
      .catch(({ statusMessage }) => handleSetError(statusMessage));
  };

  const deleteProducts = (ids: string[]) => {
    setLoading(true);
    api('product/remove', 'POST', { data: ids }, { shopId })
      .then(({ statusIsOk, statusMessage }) => {
        if (!statusIsOk) handleSetError(statusMessage);
        if (statusIsOk) {
          toastNotification(
            'success',
            ids?.length === 1
              ? 'The product has been successfully shipped to the store'
              : statusMessage,
          );
          handleGetAllProducts();
          setLoading(false);
        }
      })
      .catch(({ statusMessage }) => handleSetError(statusMessage));
  };

  const archiveProducts = (ids: string[]) => {
    setLoading(true);
    api('product/archive', 'POST', { data: ids }, { shopId })
      .then(({ statusIsOk, statusMessage }) => {
        if (!statusIsOk) handleSetError(statusMessage);
        if (statusIsOk) {
          toastNotification(
            'success',
            ids?.length === 1
              ? 'The product has been successfully shipped to the store'
              : statusMessage,
          );
          handleGetAllProducts();
          setLoading(false);
        }
      })
      .catch(({ statusMessage }) => handleSetError(statusMessage));
  };

  const sendProductsToShop = (
    ids: string[],
    type: { main: boolean; image: boolean },
    email?: string,
    date?: Date,
  ) => {
    setLoadingButton(true);
    api(`product/shop`, 'POST', { data: ids, type, email, date }, { shopId })
      .then(({ statusIsOk, statusMessage, statusType }) => {
        if (!statusIsOk) handleSetError(statusMessage, statusType);
        if (statusIsOk) {
          toastNotification('success', statusMessage);
          handleGetAllProducts();
          setLoadingButton(false);
        }
      })
      .catch(({ statusMessage, statusType }) => handleSetError(statusMessage, statusType));
  };

  const setUserAssigne = () => {
    if (!searchValue?.assigne_language) {
      return message.error('Select assigne language is missing!');
    } else {
      const language = languages.find(i => i.iso_code === searchValue?.assigne_language);

      /* eslint-disable camelcase */
      const dataObj = selectedRows?.map(item => ({
        translate_status: false,
        product_id: item,
        user_id: searchValue?.assigne,
        language_id: language?.id,
      }));
      setLoading(true);

      api('product/assign', 'POST', { data: { list: dataObj } }, { shopId })
        .then(({ statusIsOk, statusMessage }) => {
          if (!statusIsOk) toastNotification('error', statusMessage);
          if (statusIsOk) {
            handleGetAllProducts();
            setSelectedRows([]);
            setLoading(false);
          }
        })
        .catch(({ statusMessage }) => handleSetError(statusMessage));
    }
  };

  const handleExportData = async () => {
    setLoadingBtn(true);
    await api(`export/products?${params}`, 'GET', null, { shopId })
      .then(async res => {
        if (!res.statusIsOk) handleSetError(res.statusMessage);
        if (res.statusIsOk) {
          toastNotification('success', 'Successfully exported data');
          const fileName = `products-${Date.now()}.xlsx`;
          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res.result);
          const range = ws['!ref']
            ? XLSX.utils.decode_range(ws['!ref'])
            : { s: { c: 0, r: 0 }, e: { c: 0, r: 0 } };
          ws['!autofilter'] = {
            ref: `${XLSX.utils.encode_col(range.s.c)}1:${XLSX.utils.encode_col(range.e.c)}1`,
          };
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws);
          XLSX.writeFile(wb, fileName);
          setLoadingBtn(false);
        }
      })
      .catch(statusMessage => handleSetError(statusMessage));
  };

  const changeProductStatus = (id: string) => {
    api('product-status/' + id, 'PUT', {}, { shopId })
      .then(({ statusIsOk, statusMessage, error }) => {
        if (!statusIsOk) toastNotification(error ? 'error' : 'warn', statusMessage);
        if (statusIsOk) {
          toastNotification('success', 'Product sent to approve');
          handleGetAllProducts();
        }
      })
      .catch(({ statusMessage, error }) =>
        toastNotification(error ? 'error' : 'warn', statusMessage),
      );
  };

  const getShopAttributes = () => {
    setLoading(true);
    api(`mapped-attributes`, 'GET', null, { shopId })
      .then(({ statusIsOk, statusMessage, result }) => {
        if (!statusIsOk) handleSetError(statusMessage);
        if (statusIsOk) {
          const filtered = result?.map((item: any) => ({
            key: item?.id,
            id: item?.id,
            title: item?.original_name,
            checkable: false,
            selectable: false,
            children: item?.attributes
              ?.filter((attribute: AttributeModel) => !attribute.is_deleted)
              ?.map((attribute: any) => ({
                id: attribute.remote_id,
                selectable: false,
                title: attribute?.original_name,
              })),
          }));
          setLoading(false);
          setShopAttributes(filtered);
        }
      })
      .catch(({ statusMessage }) => handleSetError(statusMessage));
  };

  const getShopCategories = () => {
    api('category/shop/categories', 'GET', null, { shopId })
      .then(({ result, statusIsOk }) => {
        if (statusIsOk && result?.length !== 0) {
          const kujtesaShopCategories: any = buildSelectTree(result).find(
            (item: any) => item.id == 'a21dbc605c2f4a7f973b4c68aa571b37',
          );

          setShopCategories(kujtesaShopCategories.children);
        }
      })
      .catch(e => {
        toastNotification('error', e.error.message);
      });
  };

  const handleGetAllBrands = () => {
    setLoading(true);
    api('brand', 'GET', null, { shopId })
      .then(({ statusIsOk, statusMessage, result }) => {
        if (!statusIsOk) toastNotification('error', statusMessage);
        if (statusIsOk) {
          setBrands(result);
        }
      })
      .catch(({ statusMessage }) => handleSetError(statusMessage));
  };

  const handleGetShopBrands = () => {
    api('local-seller/brands', 'GET', null, { shopId })
      .then(({ statusIsOk, statusMessage, result }) => {
        if (!statusIsOk) toastNotification('error', statusMessage);
        if (statusIsOk) setShopBrands(result);
      })
      .catch(({ statusMessage }) => handleSetError(statusMessage));
  };

  useEffect(() => {
    getShopCategories();
    getShopAttributes();
    handleGetAllUsers();
    handleGetAllBrands();
    handleGetAllSellers();
    handleGetAllLanguages();
    getShopById();
    handleGetShopBrands();
  }, []);

  useEffect(() => {
    handleGetAllProducts();
  }, [params, categorySubmit]);

  return (
    <div className="dashboard-container product-dashboard">
      <CategoriesFilters
        setSelectedRows={setSelectedRows}
        changeProductsStatus={changeProductsStatus}
        sendProductsToShop={sendProductsToShop}
        selectedRows={selectedRows}
        shopCategories={shopCategories}
        users={users}
        loading={loadingButton}
        languages={languages}
        shop={shop}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setUserAssigne={setUserAssigne}
        archiveProducts={archiveProducts}
        deleteProducts={deleteProducts}
        setCategorySubmit={setCategorySubmit}
        categorySubmit={categorySubmit}
        searchParsedSeller={searchParsed?.seller}
        shopId={Number(shopId)}
      />

      <ListingProducts
        products={products}
        languages={languages}
        shop={shop}
        selectedRows={selectedRows}
        searchValue={searchValue}
        loading={loading}
        productCount={productCount}
        setSelectedRows={setSelectedRows}
        handleSelectAllProducts={handleSelectAllProducts}
        archiveProducts={archiveProducts}
        deleteProducts={deleteProducts}
        sendProductsToShop={sendProductsToShop}
        changeProductStatus={changeProductStatus}
        handleGetAllProducts={handleGetAllProducts}
      />

      {productCount && searchParsed ? (
        <Pagination
          showSizeChanger
          disabled={loading}
          total={productCount}
          onChange={handlePagiantion}
          showTotal={total =>
            paginationLoading ? (
              <div className="product-count-loading" style={{ marginTop: '5px' }}></div>
            ) : (
              <span>Total {total} products</span>
            )
          }
          current={searchParsed.page ? +searchParsed.page : 0}
          pageSize={searchParsed.pageSize ? +searchParsed.pageSize : 10}
        />
      ) : (
        <div className="product-count-loading" style={{ marginTop: '15px' }}></div>
      )}

      <ViewProductNewDesign
        brands={brands}
        languages={languages}
        shop={shop}
        shopCategories={shopCategories}
        shopAttributes={shopAttributes}
        handleGetAllProducts={handleGetAllProducts}
        shopBrands={shopBrands}
        shopId={Number(shopId)}
      />
    </div>
  );
};

export default CategoriesUpdatePage;
