import { Col, Form, Input, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { Images } from '../../../../assets/images/Index';
import { LanguageModel } from '../../../../interfaces';
import { ProductTranslateLangProps } from '../Product.types';

interface ShopLanguage {
  id: string;
  name: string;
  default: boolean;
  send_translation: boolean;
}

export const ProductTranslateView = ({
  isReadonly,
  product,
  translate,
  languages,
  shop,
  defaultLanguage,
  setTranslate,
  disabled,
}: ProductTranslateLangProps) => {
  const [form] = Form.useForm();
  const [language, setLanguage] = useState<any | null>(null);

  const handleOnChangeDescription = (description: string) => {
    setTranslate((t: any) => ({
      ...t,
      long_description: description.replaceAll(/background-color:\s*[\w\s(),]+;/g, ''),
    }));
  };

  const handleOnChangeLanguage = (value: string) => {
    const lang = languages?.find((item: LanguageModel) => item.iso_code === value) || null;

    if (lang) {
      setLanguage(lang);
    }

    const current = product?.product_translates?.find(
      item => item.language_id === languages?.find((item: any) => item.iso_code === value)?.id,
    );
    const languageID = lang?.id ? { language_id: lang.id } : null;

    setTranslate(current ? current : languageID);

    form.setFieldsValue({
      name: lang && current ? current?.name : '',
      short_description: lang && current ? current?.short_description : '',
    });
  };

  useEffect(() => {
    if (product && shop && languages?.length !== 0) {
      const shopLanguages: ShopLanguage[] = Object.values(
        JSON.parse(String(shop?.languages)),
      ) as ShopLanguage[];
      const lang = shopLanguages.find(lang => lang.send_translation);

      const currentTranslate = product?.product_translates?.find(
        item => item.language_id == lang?.id || null,
      );

      delete currentTranslate?.created_at;
      delete currentTranslate?.updated_at;

      setLanguage(lang || null);
      const languageID = lang?.id ? { language_id: lang.id } : null;
      setTranslate(currentTranslate || languageID);

      form.setFieldsValue({
        name: currentTranslate?.name,
        short_description: currentTranslate?.short_description,
      });
    }
  }, [product, languages, shop]);

  return (
    <>
      <div className="translate-header">
        <Form.Item>
          <span className="language-title">
            {defaultLanguage ? 'Default' : 'Translate'} language
          </span>
          {defaultLanguage ? (
            <Select
              style={{ width: 'auto', minWidth: '100px' }}
              defaultValue="en"
              disabled
              suffixIcon={<img src={Images.arrowCDown} />}
              getPopupContainer={trigger => trigger.parentNode}
            >
              <Select.Option value="en">English</Select.Option>
            </Select>
          ) : (
            <Select
              style={{ width: 'auto', minWidth: '100px' }}
              className="translate-select"
              showSearch={true}
              value={language?.name}
              defaultValue="AL"
              optionFilterProp="children"
              onChange={handleOnChangeLanguage}
              suffixIcon={<img src={Images.arrowCDown} />}
              getPopupContainer={trigger => trigger.parentNode}
            >
              {shop?.languages &&
                Object.entries(JSON.parse(String(shop?.languages))).map(
                  ([key, value]: [string, any]) => (
                    <Select.Option value={key} key={key}>
                      {value.name}
                    </Select.Option>
                  ),
                )}
            </Select>
          )}
        </Form.Item>
      </div>
      <div className="form_container">
        <Form
          form={form}
          className="form-container second_container"
          scrollToFirstError
          layout="vertical"
          id="translate-form"
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Product name"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: true }]}
                name="name"
              >
                <Input
                  onChange={e => {
                    setTranslate((t: any) => ({
                      ...t,
                      name: e.target?.value,
                    }));
                  }}
                  name="name"
                  disabled={disabled}
                  readOnly={defaultLanguage || isReadonly}
                  className={defaultLanguage ? 'readOnly' : ''}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Short description"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name="short_description"
              >
                <Input.TextArea
                  placeholder="-"
                  readOnly={defaultLanguage}
                  disabled={disabled}
                  className={defaultLanguage ? 'readOnly' : ''}
                  onChange={e => {
                    setTranslate((t: any) => ({
                      ...t,
                      short_description: e.target?.value,
                    }));
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24} className="description_wrapper">
              <p className="long_description_title">Long description</p>

              {defaultLanguage ? (
                <ReactQuill
                  readOnly
                  className="input-disabled"
                  theme="snow"
                  modules={{
                    toolbar: [
                      [{ font: [] }],
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      ['bold', 'italic', 'underline', 'strike'],
                      [{ color: [] }, { background: [] }],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      ['link'],
                    ],
                  }}
                  value={
                    product?.product_translates?.find(
                      item => item.language_id === '2fbb5fe2e29a4d70aa5854ce7ce3e20b',
                    )?.long_description || 'en'
                  }
                />
              ) : (
                <ReactQuill
                  className="input-disabled"
                  value={translate?.long_description || '-'}
                  theme="snow"
                  readOnly={disabled}
                  modules={{
                    toolbar: [
                      [{ font: [] }],
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      ['bold', 'italic', 'underline', 'strike'],
                      [{ color: [] }, { background: [] }],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      ['link'],
                    ],
                  }}
                  onChange={handleOnChangeDescription}
                />
              )}
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};
